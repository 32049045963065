
<template>
  <base-section
    id="news"
    space="72"
  >
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
        >
          <news-card
            v-for="(article, i) in articles"
            :key="i"
            :divider="i + 1 < articles.length"
            class="mb-12"
            v-bind="article"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <news-archives />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNewsAlt',

    components: {
      NewsArchives: () => import('@/components/news/Archives'),
      NewsCard: () => import('@/components/news/Card'),
      NewsCategories: () => import('@/components/news/Categories'),
      NewsSearch: () => import('@/components/news/Search'),
      NewsTags: () => import('@/components/news/Tags'),
    },

    data: () => ({
      articles: [
        {
          icon: 'mdi-text',
          date: 'Dec 28, 2020',
          newspaper: 'New Straits Times',
          readMore: true,
          title: 'Hong Seng buys 42ha land in Kedah Rubber City for RM46mil',
          link: 'https://www.nst.com.my/business/2020/12/652797/hong-seng-buys-42ha-land-kedah-rubber-city-rm46mil',
          src: require('@/assets/news_4.jpg'),
        },
        {
          icon: 'mdi-text',
          date: 'Nov 27, 2020',
          newspaper: 'New Straits Times',
          title: 'Hong Seng on track with healthcare, hire purchase diversification',
          readMore: true,
          src: require('@/assets/news_6.jpg'),
          link: 'https://www.nst.com.my/business/2020/11/644911/hong-seng-track-healthcare-hire-purchase-diversification',
        },
        {
          icon: 'mdi-text',
          date: 'Aug 26, 2020',
          newspaper: 'The Star',
          title: 'MSCM ventures into glove production',
          readMore: true,
          src: require('@/assets/news_7.jpeg'),
          link: 'https://www.thestar.com.my/business/business-news/2020/08/26/mscm-ventures-into-glove-production',
        },
        {
          icon: 'mdi-text',
          date: 'Aug 25, 2020',
          newspaper: 'The China Press',
          title: 'mscm控股进军手套业-明年4月投产带动获利',
          readMore: true,
          src: require('@/assets/news_8.jpeg'),
          link: 'https://www.chinapress.com.my/20200825/mscm%E6%8E%A7%E8%82%A1%E8%BF%9B%E5%86%9B%E6%89%8B%E5%A5%97%E4%B8%9A-%E6%98%8E%E5%B9%B44%E6%9C%88%E6%8A%95%E4%BA%A7%E5%B8%A6%E5%8A%A8%E8%8E%B7%E5%88%A9/',
        },
        {
          icon: 'mdi-text',
          date: 'Aug 25, 2020',
          newspaper: 'Borneo Post Online',
          title: 'MSCM appoints turnkey project partner to kickstart glove business',
          readMore: true,
          src: require('@/assets/news_1.jpeg'),
          link: 'https://www.theborneopost.com/2020/08/25/mscm-appoints-turnkey-project-partner-to-kickstart-glove-business/',
        },
      ],
    }),
  }
</script>
